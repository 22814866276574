<template>
  <v-data-table
    v-model="arModuleList"
    :headers="headers"
    :items="items"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
    show-select
    @item-selected="onToggle"
  >
    <template #[`header.data-table-select`]>
      <span />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel, Watch } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import CacheMixin from "@/mixins/CacheMixin";
import type { ModuleData } from "@planetadeleste/vue-mc-gw";
import { Company, ModuleCollection } from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import { findIndex, map } from "lodash";
import { ConfigModule } from "@/store/config";
import { EventBus } from "@/services/event-bus";

type DataTableSelectRow = {
  item: ModuleData;
  value: boolean;
};

@Component
export default class CompanyModules extends Mixins(DataTableMixin, CacheMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
  ];
  arModuleList: ModuleData[] = [];
  obCollection: ModuleCollection = new ModuleCollection();

  get cacheKey() {
    return "Module";
  }

  get cacheIdentifier() {
    return "models";
  }

  get company() {
    return AppModule.company;
  }

  get companyId(): number | undefined {
    return this.obCompany ? this.obCompany.id : undefined;
  }

  get items(): ModuleData[] {
    let arItems = this.cache<ModuleData[]>();

    if (!arItems || !arItems.length) {
      arItems = this.obCollection.getModelList() as ModuleData[];
    }

    return arItems;
  }

  @Watch("companyId")
  async onChangeCompany() {
    this.loading = true;
    await this.loadCompanyModules();
    this.loading = false;
  }

  async load() {
    this.loading = true;
    await this.loadCompanyModules();
    const arItems = this.cache<ModuleData[]>();

    if (arItems && arItems.length) {
      this.loading = false;
      return;
    }

    this.obCollection.clear();
    await this.obCollection.list();
    this.cache(this.obCollection.getModelList());

    this.loading = false;
  }

  async loadCompanyModules() {
    if (!this.obCompany || !this.obCompany.id) {
      return;
    }

    const obResponse = await this.obCompany.getModules();
    this.arModuleList = obResponse.getData().data;
  }

  async onToggle(obValue: DataTableSelectRow) {
    const bSelected = obValue.value;
    const obItem = obValue.item;
    const arModuleIdList = map(this.arModuleList, "id");

    // Add selected id
    if (bSelected) {
      arModuleIdList.push(obItem.id);
    } else {
      // Remove selected id
      const idx = findIndex(arModuleIdList, (itemId) => itemId === obItem.id);
      if (idx !== -1) {
        arModuleIdList.splice(idx, 1);
      }
    }

    this.loading = true;

    // Store selected invoice types ids
    await this.obCompany.addUpdateModules(arModuleIdList);

    // Check for current selected company to reload invoice types
    if (this.obCompany.id === this.company.id) {
      await ConfigModule.loadCompanyModules(true);
      await ConfigModule.loadCompanyInvoiceTypes(true);
    }

    EventBus.emit("company.update.modules", this.obCompany.id);

    this.loading = false;
  }

  mounted() {
    this.mapDTHeaders();
    this.load();
  }
}
</script>
